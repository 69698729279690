import ServerError from './server';

/**
 * Timeout error
 */
export default class TimeoutError extends ServerError {

  /**
   * Constructor
   */
  constructor(message, data) {

    //Parent constructor
    super(message, data, 504);
  }
}
