import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/services/auth';
import store from '@/store';

import Home from '@/components/home/home.vue';
import ResetPassword from '@/components/user/reset-password';
import ForgotPassword from '@/components/user/forgot-password';
import Login from '@/components/user/login';
import Register from '@/components/user/register';
import UserDetails from '@/components/user/user-details';
import OAuthCallback from '@/components/user/oauth-callback';
import Morble from '@/components/morble/morbles';
import MorbleOverview from '@/components/morble/overview';
import MorbleScan from '@/components/morble/scan';
import MorbleView from '@/components/morble/view';
import ErrorRoute from '@/components/error/error';

Vue.use(Router);

//NOTE: When props is set to true, the route.params will be set as the component props

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/login', name: 'login', component: Login },
    { path: '/register', name: 'register', component: Register },
    { path: '/user_details', name: 'user_details', component: UserDetails, meta: { auth: true } },
    { path: '/oauth/callback', name: 'oAuthCallback', component: OAuthCallback, props: true },
    { path: '/reset-password/:accessToken', name: 'resetPassword', component: ResetPassword, props: true },
    { path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword },
    {
      path: '/morble', component: Morble,
      // meta: { auth: true },
      children: [
        { path: 'overview', name: 'morble.overview', component: MorbleOverview },
        { path: 'scan', name: 'morble.scan', component: MorbleScan },
        { path: 'view/:id', name: 'morble.view', component: MorbleView, props: true },
      ],
    },
    { path: '/error', name: 'error', component: ErrorRoute, props: true },
    { path: '/:code([a-zA-Z0-9]{5,6})', name: 'link', component: MorbleView, props: true },

    // Verify email & create new user
    { path: '/verify/:accessToken', name: 'verifyUser', component: UserDetails, props: true },
  ],
});

/**
 * Authentication guard
 */
router.beforeEach(async (to, from, next) => {

  //Initalize authentication service
  auth.init();

  //Clear page data
  store.dispatch('page/clear');

  //Check if route requires authentication and if not authenticated
  if (to.matched.some(record => record.meta.auth)) {
    if (!auth.hasToken()) {
      return next({
        name: 'login',
        query: { redirect: to.name },
      });
    }
  }

  next();
});

export default router;
