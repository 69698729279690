<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <router-link :to="{ name: 'home' }">
        <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
      </router-link>
    </div>

    <div class="PageBlock PageBlock--contained">
      <p class="text-center" v-if="error" v-html="error" />

      <div class="Group">
        <input
          v-model="user.password"
          type="password"
          class="Input"
          placeholder="Enter new password"
        >
      </div>
      <div class="Group">
        <input
          v-model="user.confirmPassword"
          type="password"
          class="Input"
          placeholder="Repeat new password"
        >
      </div>
      <div class="InputHint" v-if="$v.user.password.$error || $v.user.confirmPassword.$error">
        <p v-if="!$v.user.password.required">Password is required</p>
        <p v-if="!$v.user.password.minLength">Password needs to be at least 10 characters</p>
        <p v-if="!$v.user.confirmPassword.sameAsPassword">Passwords don't match</p>
      </div>

      <div class="ButtonContainer">
        <router-link
          class="Button Button--s Button--muted"
          :to="{name: 'login'}"
        >Login</router-link>
        <button class="Button Button--s Button--primary" @click="resetPassword">
          Set password
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  props: {
    accessToken: String,
  },

  data() {
    return {
      user: {
        password: '',
        confirmPassword: '',
      },
      error: '',
    };
  },

  validations: {
    user: {
      password: {
        required,
        minLength: minLength(10),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Reset Password',
    });
  },

  methods: {
    async resetPassword() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const { user } = this;

      //Reset password
      try {
        await UserApi.resetPassword(user, this.accessToken);
        this.$notify('Successfully updated your details');
        this.$router.push({ name: 'login' });
      }
      catch (error) {
        if (error.message === 'Already used') {
          this.error = `This password reset link has already been used.<br />
                        You can request another <a href="/forgot-password">here</a>.`;
        }
        else {
          this.error = error;
        }
      }
    },
  },
};
</script>
