import ResponseError from './response';

/**
 * Client error
 */
export default class ClientError extends ResponseError {

  /**
   * Constructor
   */
  constructor(message, data, status = 400) {

    //Parent constructor
    super(message, data, status);
  }
}
