import BaseModel from './base.model';
import ArtefactApi from '@/api/artefact.api';
import auth from '@/services/auth';
import cfg from '@/services/cfg';
import artefactTypes from '@/constants/artefact-types';

const MAX_THUMBNAILS = 9;

export default class Artefact extends BaseModel {

  /**
   * Is photo/video flags
   */
  get isPhoto() {
    return (this.type === artefactTypes.PHOTO);
  }
  get isVideo() {
    return (this.type === artefactTypes.VIDEO);
  }

  /**
   * Check if has thumbnail
   */
  get hasThumb() {
    const {type} = this;
    const {pathThumb} = this[type];
    return !!pathThumb;
  }

  /**
   * Artefact URL
   */
  get url() {
    const {type} = this;
    const {path} = this[type];
    const base = cfg.api.baseUrl;
    const qs = auth.getQueryString();
    return `${base}/artefact/${type}/${path}${qs}`;
  }

  get thumbUrl() {
    const {type} = this;
    const {pathThumb} = this[type];
    const base = cfg.api.baseUrl;
    const qs = auth.getQueryString();
    return `${base}/artefact/${type}/${pathThumb}${qs}`;
  }

  get thumbOptionUrls() {
    const {type} = this;
    const {thumbOptionsPaths} = this[type];
    const base = cfg.api.baseUrl;
    const qs = auth.getQueryString();
    const urls = thumbOptionsPaths.map(path => `${base}/artefact/${type}/${path}${qs}`);
    return urls.slice(0, MAX_THUMBNAILS);
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return ArtefactApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const { id } = this;
    return ArtefactApi.remove(id);
  }

  setThumbnail(thumbIndex) {
    const { id } = this;
    return ArtefactApi.setThumbnail(id, thumbIndex);
  }

  regenerateThumbnails() {
    const { id } = this;
    return ArtefactApi.regenerateThumbnails(id);
  }
}
