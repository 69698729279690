import UserApi from '@/api/user.api';
import { SET_USER, SET_FACEBOOK_ACCESS_TOKEN } from './types';

/**
 * Load session user
 */
export const loadUser = async ({ commit }) => {
  const user = await UserApi.me();

  commit(SET_USER, user);
};

/**
 * Unload session user
 */
export const unloadUser = async ({ commit }) => {

  //Clear from store
  commit(SET_USER, null);
};

export const setFacebookAccessToken = async ({ commit }, facebookAccessToken) => {
  commit(SET_FACEBOOK_ACCESS_TOKEN, facebookAccessToken);
};
