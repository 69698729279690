<template>
  <div class="Scanner">
    <canvas id="scannerCanvas"></canvas>
    <input type="range" id="zoomRange" class="ZoomControl" disabled>
  </div>
</template>

<script>
import jsQR from 'jsqr';

export default {
  data() {
    return {
      video: null,
      canvas: null,
      ctx: null,
    };
  },
  async mounted() {

    //Get elements
    this.createVideoElement();
    this.getCanvasElement();

    //Bind frame handler
    this.bindFrameHandler();

    //Get user media and determine capabilities
    await this.getUserMedia();
    await this.determineCapabilities();
  },
  beforeDestroy() {

    //Clean up
    this.cleanUp();
  },

  //Methods
  methods: {

    /**
     * Create video element
     */
    createVideoElement() {
      this.video = document.createElement('video');
    },

    /**
     * Get canvas elements
     */
    getCanvasElement() {
      this.canvas = document.getElementById('scannerCanvas');
      this.ctx = this.canvas.getContext('2d');
    },

    /**
     * Bind frame handler
     */
    bindFrameHandler() {
      this.processFrameBound = this.processFrame.bind(this);
    },

    /**
     * Get user media
     */
    async getUserMedia() {

      //Get stream from user media
      this.stream = await navigator.mediaDevices
        .getUserMedia({video: {facingMode: 'environment'}});

      //No stream?
      if (!this.stream) {
        return;
      }

      //Set as source object for video
      this.video.srcObject = this.stream;
      this.video.setAttribute('playsinline', true); //Tell iOS safari we don't want fullscreen
      this.video.play();

      //Request animation frame
      this.nextFrame();
    },

    /**
     * Stop user media
     */
    stopUserMedia() {

      //No stream?
      if (!this.stream) {
        return;
      }

      //Clear video object
      this.video.srcObject = null;

      //Stop tracks
      this.stream
        .getTracks()
        .forEach(track => track.stop());
    },

    /**
     * Determine capabilities
     */
    async determineCapabilities() {

      //Wait for stream to get ready
      await new Promise(r => setTimeout(r, 1000));

      //No stream?
      if (!this.stream) {
        return;
      }

      //Get video track capabilities
      const {stream} = this;
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();
      const capabilities = track.getCapabilities();

      //Set capabilities
      this.capabilities = capabilities;

      //Check if we can zoom in
      if ('zoom' in capabilities) {

        //Get settings and input element
        const {min, max, step} = capabilities.zoom;
        const value = settings.zoom;
        const disabled = false;
        const input = document.getElementById('zoomRange');

        //Apply settings
        Object.assign(input, {min, max, step, value, disabled});

        //Apply event handler
        input.oninput = function(event) {
          track.applyConstraints({advanced: [{zoom: event.target.value}]});
        };
      }
    },

    /**
     * Next frame
     */
    nextFrame() {
      window.requestAnimationFrame(this.processFrameBound);
    },

    /**
     * Process frame
     */
    processFrame() {

      //Destroying component?
      if (this.isDestroying) {
        return;
      }

      //Get data
      const {video, canvas, ctx} = this;

      //Not enough data yet?
      if (video.readyState !== video.HAVE_ENOUGH_DATA) {
        return this.nextFrame();
      }

      //Set canvas size
      const {videoHeight: height, videoWidth: width} = video;
      canvas.height = height;
      canvas.width = width;

      //Draw video onto canvas
      ctx.drawImage(video, 0, 0, width, height);

      //Determine width and height
      const cWidth = Math.min(width, 200);
      const cHeight = Math.min(height, 200);

      //Determine coordinates for crop
      const cTop = Math.round((height / 2) - (cHeight / 2));
      const cLeft = Math.round((width / 2) - (cWidth / 2));
      const cBottom = Math.round((height / 2) + (cHeight / 2));
      const cRight = Math.round((width / 2) + (cWidth / 2));

      //Draw frame
      this.drawFrame(cTop, cLeft, cBottom, cRight, width, height);

      //Find QR code asynchronously
      setTimeout(() => {

        //Already processing?
        if (this.isProcessingQR) {
          return;
        }

        //Mark as processing
        this.isProcessingQR = true;

        //Get image data and find QR code
        const imageData = ctx.getImageData(cLeft, cTop, cWidth, cHeight);
        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

        //Mark as processing
        this.isProcessingQR = false;

        //Code found?
        if (code) {

          //Draw bounding box
          this.drawBoundingBox(code.location);

          //Emit code if new
          if (this.lastData !== code.data) {
            this.$emit('code', {code});
            this.lastData = code.data;
          }
        }
      }, 0);

      //Process next frame
      this.nextFrame();
    },

    /**
     * Draw frame
     */
    drawFrame(top, left, bottom, right, width, height) {

      //Line properties
      const color = '#42f474';
      const boxColor = '#000000';
      const alpha = 0.5;
      const size = 2;

      //Draw lines
      this.drawLine({x: left, y: top}, {x: right, y: top}, color, size);
      this.drawLine({x: right, y: top}, {x: right, y: bottom}, color, size);
      this.drawLine({x: right, y: bottom}, {x: left, y: bottom}, color, size);
      this.drawLine({x: left, y: bottom}, {x: left, y: top}, color, size);

      //Draw transparent overlay boxes
      this.drawRect(0, 0, width, top, boxColor, alpha);
      this.drawRect(0, bottom, width, height - bottom, boxColor, alpha);
      this.drawRect(0, top, left, bottom - top, boxColor, alpha);
      this.drawRect(right, top, left, bottom - top, boxColor, alpha);
    },

    /**
     * Helper to draw bounding box
     */
    drawBoundingBox(location) {

      //Get location data
      const {
        topLeftCorner, topRightCorner, bottomLeftCorner, bottomRightCorner,
      } = location;

      //Line properties
      const color = '#be4d9d';
      const size = 4;

      //Draw lines
      this.drawLine(topLeftCorner, topRightCorner, color, size);
      this.drawLine(topRightCorner, bottomRightCorner, color, size);
      this.drawLine(bottomRightCorner, bottomLeftCorner, color, size);
      this.drawLine(bottomLeftCorner, topLeftCorner, color, size);
    },

    /**
     * Helper to draw line on canvas
     */
    drawLine(begin, end, color, size, alpha = 1) {
      this.ctx.beginPath();
      this.ctx.moveTo(begin.x, begin.y);
      this.ctx.lineTo(end.x, end.y);
      this.ctx.globalAlpha = alpha;
      this.ctx.lineWidth = size;
      this.ctx.strokeStyle = color;
      this.ctx.stroke();
    },

    /**
     * Helper to draw rectangle
     */
    drawRect(x1, y1, x2, y2, color, alpha = 1) {
      this.ctx.globalAlpha = alpha;
      this.ctx.fillStyle = color;
      this.ctx.fillRect(x1, y1, x2, y2);
    },

    /**
     * Clean up data
     */
    cleanUp() {

      //Flag as destroying
      this.isDestroying = true;

      //Stop tracks
      this.stopUserMedia();
    },
  },
};
</script>

<style lang="scss">
.ZoomControl {
  position: absolute;
  bottom: 2rem;
  width: 60vw;
  left: 20vw;
}
</style>
