<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <router-link :to="{name: 'home'}">
        <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
      </router-link>
    </div>

    <div class="PageBlock PageBlock--padded" v-if="isLoggingIn">
      <spinner />
    </div>

    <div class="PageBlock PageBlock--contained" v-else-if="isUsingCredentials">
      <div class="Group">
        <input v-model="credentials.email" type="text" class="Input" placeholder="Email" />
        <div class="InputHint" v-if="$v.credentials.email.$error">
          <p v-if="!$v.credentials.email.required">Email address is required</p>
          <p v-if="!$v.credentials.email.email">Invalid email address</p>
        </div>
      </div>
      <div class="Group">
        <input v-model="credentials.password" type="password" class="Input" placeholder="Password" />
        <div class="InputHint" v-if="$v.credentials.password.$error">
          <p v-if="!$v.credentials.password.required">Password is required</p>
        </div>
      </div>

      <p v-if="isErrorInvalidLogin">Invalid login, please double check your email and password</p>

      <div class="ButtonContainer">
        <button
          class="Button Button--s Button--muted"
          @click="toggleUseCredentials"
        >Back</button>
        <button
          class="Button Button--s Button--primary"
          @click="loginWithCredentials"
        >Sign in</button>
      </div>
      <info-footer>
        <router-link :to="{name: 'forgotPassword'}">Forgot password?</router-link>
      </info-footer>
    </div>

    <div class="PageBlock" v-else>
      <div v-if="message" class="message">
        <p class="text-center">{{message}}</p>
      </div>

      <div class="ButtonContainerVertical">
        <button
          class="Button Button--main Button--email"
          @click="toggleUseCredentials"
        >
          <div class="ButtonContent">
            <i class="Icon">email</i>
            Sign in with email
          </div>
        </button>
        <button
          class="Button Button--main Button--google"
          @click="loginWithOAuth('google')"
        >
          <div class="ButtonContent">
            <i class="IconFaux"><img src="/images/icon-google.png"></i>
            Sign in with Google
          </div>
        </button>
        <button
          class="Button Button--main Button--facebook"
          @click="loginWithOAuth('facebook')"
        >
          <div class="ButtonContent">
            <i class="IconFaux"><img src="/images/icon-facebook.png"></i>
            Sign in with Facebook
          </div>
        </button>
        <button class="Button Button--s" @click="$router.push('register')">
          <div class="ButtonContent">
            <i class="Icon">person_add</i>
            Create account
          </div>
        </button>
      </div>
      <info-footer>
        <a href="https://morble.co">What is Morble?</a>
      </info-footer>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';
import NotAuthenticatedError from '@/errors/not-authenticated';
import InfoFooter from '@/components/shared/info-footer';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: { InfoFooter },

  computed: {
    message() {
      return this.$route.query?.message;
    },
  },

  data() {
    return {
      isErrorInvalidLogin: false,
      isUsingCredentials: false,
      isLoggingIn: false,
      credentials: {
        email: '',
        password: '',
      },
    };
  },

  validations: {
    credentials: {
      email: { required, email },
      password: { required },
    },
  },

  beforeRouteEnter(to, from, next) {
    // if (auth.hasToken()) {
    //   return next({name: 'home'});
    // }
    next();
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Login',
    });
  },

  methods: {
    toggleUseCredentials() {
      this.isUsingCredentials = !this.isUsingCredentials;
    },

    async loginWithCredentials() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const { email, password } = this.credentials;
      const { redirect } = this.$route.query;

      this.isErrorInvalidLogin = false;
      this.isLoggingIn = true;

      //Login
      try {
        await auth
          .loginWithCredentials(email, password, redirect)
          .finally(() => this.isLoggingIn = false);
      }
      catch (error) {
        if (error instanceof NotAuthenticatedError) {
          return this.isErrorInvalidLogin = true;
        }

        //Process other errors
        this.$err.process(error);
      }
    },

    loginWithOAuth(provider) {
      const { redirect } = this.$route?.query;

      if (redirect) {
        auth.loginWithOAuth(provider, null, { redirect });
      }
      else {
        auth.loginWithOAuth(provider);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  margin-bottom: 1rem;
  p {
    text-align: center;
  }
}
</style>
