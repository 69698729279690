<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <router-link
        :to="{name: 'home'}"
      >
        <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
      </router-link>
    </div>

    <div class="PageBlock PageBlock--contained">
      <div v-if="isWaitingForEmailVerification">
        <h1>Thanks for registering!</h1>

        <p>We've sent you an email to verify your email address.</p>
      </div>

      <div v-else>
        <h1>Create new account</h1>

        <p v-if="comingFromFailedOAuth">We didn't find a Morble account associated with that
          <span class="capitalise">{{oAuthProvider}}</span> account. But you can create one now:
        </p>

        <p class="text-center" v-if="error" v-html="error" />

        <div class="Group">
          <input
            v-model="user.name"
            type="text"
            class="Input"
            placeholder="Your name"
          >
          <div class="InputHint" v-if="$v.user.name.$error">
            <p v-if="!$v.user.name.required">Name is required</p>
          </div>
        </div>

        <div class="Group">
          <input
            v-model="user.email"
            type="email"
            class="Input"
            placeholder="Email address"
          >
          <div class="InputHint" v-if="$v.user.email.$error">
            <p v-if="!$v.user.email.required">Email address is required</p>
            <p v-if="!$v.user.email.email">Invalid email address</p>
          </div>
        </div>
        <div class="Group">
          <input
            v-model="user.password"
            type="password"
            class="Input"
            placeholder="Choose password"
          />
          <div class="InputHint" v-if="$v.user.password.$error">
            <p v-if="!$v.user.password.required">Password is required</p>
          </div>
        </div>
        <div class="Group">
          <input
            v-model="user.confirmPassword"
            type="password"
            class="Input"
            placeholder="Confirm password"
          />
          <div class="InputHint" v-if="$v.user.password.$error || $v.user.confirmPassword.$error">
            <p v-if="!$v.user.password.minLength">Password needs to be at least 10 characters</p>
            <p v-if="!$v.user.confirmPassword.sameAsPassword">Passwords don't match</p>
          </div>
        </div>

        <div class="ButtonContainer">
          <button
            class="Button Button--s Button--primary"
            @click="register"
            :disabled="isRegistering"
          >
            <i class="Icon">add</i>Create account
          </button>
        </div>

        <p>
          Already have an account? <router-link to="login">Login here</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';
import UserApi from '@/api/user.api';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      error: '',
      isRegistering: false,
      isWaitingForEmailVerification: false,
      comingFromFailedOAuth: false,
      oAuthProvider: null,
    };
  },

  validations: {
    user: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(10),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  beforeMount() {
    this.$store.dispatch('page/setup', {
      title: 'Create account',
    });

    // If the server has redirected us to this page with OAuth provider info
    const { query } = this.$route;
    if (query.provider) {
      this.comingFromFailedOAuth = true;
      this.oAuthProvider = query.provider;
      this.user.name = `${query.firstName} ${query.lastName}`;
      this.user.email = query.email;
      this.user.profile = {
        provider: query.provider,
        id: query.id,
        email: query.email,
        firstName: query.firstName,
        lastName: query.lastName,
      };
    }
  },

  methods: {
    async register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.isRegistering = true;
      const { user } = this;

      if (this.oAuthProvider) {
        await UserApi.register(user, true);
        auth.loginWithOAuth(this.oAuthProvider, 'login', { redirect: 'home' });
      }
      else {
        try {
          await UserApi.register(user);
          this.isRegistering = false;
          this.isWaitingForEmailVerification = true;
        }
        catch (err) {
          if (err.message === 'duplicated_email') {
            this.error = `That email address is already in use.<br /> If it's yours you can log in <a href="/login">here</a>.`;
          }
          else {
            this.error = 'Something went wrong';
          }
          this.isRegistering = false;
        }
      }

    },
  },
};
</script>

<style scoped>
.capitalise {
  text-transform: capitalize;
}
</style>
