import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import cfg from '@/services/cfg';
import AuthApi from '@/api/auth.api';
import Token from '@/models/token.model';

/**
 * Create mixin to load the authentication service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.auth) {
      this.$auth = options.auth;
    }
    else if (options.parent && options.parent.$auth) {
      this.$auth = options.parent.$auth;
    }
  },
});

class AuthService {
  init() {

    //Already have token
    if (this.token) {
      return;
    }

    //Check if there is an existing token
    this.token = Token.existing();
  }

  /**
   * Check if we're currently authenticated (e.g. have a token)
   */
  hasToken() {
    return (this.token && this.token.accessToken);
  }

  /**
   * Get access token
   */
  getToken() {

    //No token
    if (!this.token) {
      return '';
    }

    //Return access token
    return this.token.accessToken;
  }

  /**
   * Set access token
   */
  setToken(accessToken) {

    //Set token
    this.token = Token.use(accessToken);
  }

  /**
   * Check if access token expired
   */
  isTokenExpired() {
    return (this.token && this.token.isExpired());
  }

  /**
   * Check if access token is about to expire
   */
  isTokenExpiring(offset = 60) {
    return (this.token && this.token.isExpiring(offset));
  }

  /**
   * Get query string
   */
  getQueryString() {

    //No access token
    if (!this.token || !this.token.accessToken) {
      return '';
    }

    //Return header
    return `?access_token=${this.token.accessToken}`;
  }

  /**************************************************************************
   * Authentication flow control
   ***/

  /**
	 * Login with credentials
	 */
  async loginWithCredentials(email, password, redirect = cfg.auth.homeRoute) {

    //Obtain token from server
    this.token = await Token.obtain('password', { email, password });

    this.onAuthenticated(redirect);
  }

  async loginWithOAuth(provider, action = 'login', state = {}) {

    //If object, get ID
    if (typeof provider === 'object') {
      provider = provider.id;
    }

    //Always append action to state
    state.action = action;

    //Create serialized params from state object
    const params = Object
      .entries(state)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    //Determine path
    const base = cfg.api.baseUrl;
    const path = this.getOAuthPath(action);
    const qs = params ? `?${params}` : '';

    //Wrap in promise
    return Promise
      .resolve()
      .then(() => {
        document.location.href = `${base}/${path}/${provider}${qs}`;
      });
  }

  /**
   * Login with existing access token (from oAuth flow)
   */
  async loginWithToken(accessToken, redirect = cfg.auth.homeRoute, facebookAccessToken) {

    // save Facebook access token
    if (facebookAccessToken) {
      store.dispatch('session/setFacebookAccessToken', facebookAccessToken);
    }

    //Set token
    this.token = Token.use(accessToken);

    //On authenticated handler
    this.onAuthenticated(redirect);
  }

  async logout(isAutomatic = false) {

    //Clear token
    this.token = Token.clear();

    //Call unauthenticated handler direclty
    this.onUnauthenticated();

    //Revoke refresh token on server if manually logged out
    if (!isAutomatic) {
      await AuthApi.revoke();
    }
  }

  /**
   * Refresh access token
   */
  async refresh() {

    //Return existing refresh promise if already refreshing
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    //Refresh
    return this.refreshPromise = Token
      .obtain('refreshToken')
      .then(token => this.token = token)
      .finally(() => this.refreshPromise = null);
  }

  /**
   * Get oAuth path for given action
   */
  getOAuthPath(action) {
    switch (action) {
      case 'login':
      case 'connect':
      default:
        return 'auth';
    }
  }

  userId() {
    return this.token?.payload?.userId;
  }

  /**************************************************************************
   * Auth status change handlers
   ***/

  async onAuthenticated(redirect) {

    //Load user
    store.dispatch('session/loadUser');

    // Redirect if needed, trying named routes first, then just path
    if (redirect) {
      if (router.options.routes.some(({ name }) => name === redirect)) {
        router.push({ name: redirect });
      }
      else {
        router.push(redirect);
      }
    }
  }

  onUnauthenticated() {

    //Unload user
    store.dispatch('session/unloadUser');

    //Redirect back to login unless allowed to be here
    if (!cfg.auth.allowedUnauthenticated.includes(router.currentRoute.name)) {
      router.push({ name: cfg.auth.loginRoute });
    }
  }
}

/**
 * Export singleton instance
 */
export default new AuthService();
