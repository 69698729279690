// Based on https://medium.com/@mrjohnkilonzi/a-simple-facebook-login-component-in-vue-js-5ee71997bb97
import cfg from '@/services/cfg';

const { facebook: { appId } } = cfg;

export default function facebookSdk() {
  return new Promise(resolve => {
    if (document.getElementById('facebook-jssdk')) {
      return;
    }

    const fjs = document.getElementsByTagName('script')[0];
    const js = document.createElement('script');
    js.onload = () => {
      window.FB.init({
        appId,
        cookie: true,
        version: "v15.0"
      });
      resolve();
    };

    js.id = 'facebook-jssdk';
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  });
};
