<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
    </div>

    <div class="PageBlock PageBlock--contained">
      <p class="text-center" v-if="error">{{error}}</p>

      <div v-if="emailedUser" class="text-center">
        Thank you. If there is a Morble account associated with {{user.email}}, we&rsquo;ll email password reset
         instructions.
      </div>

      <div v-else>
        <div class="Group">
          <input
            v-model="user.email"
            type="text"
            class="Input"
            placeholder="Enter your email address"
          />
          <div class="InputHint" v-if="$v.user.email.$error">
            <p v-if="!$v.user.email.required">Email address is required</p>
            <p v-if="!$v.user.email.email">Invalid email address</p>
          </div>
        </div>

        <div class="ButtonContainer">
          <router-link class="Button Button--s Button--muted" :to="{name: 'login'}">
            Back
          </router-link>
          <button class="Button Button--s Button--primary" @click="submit">
            Submit
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import { required, email } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      user: {
        email: '',
      },
      error: '',
      emailedUser: false,
    };
  },

  validations: {
    user: {
      email: { required, email },
    },
  },

  beforeMount() {
    this.$store.dispatch('page/setup', {
      title: 'Forgot Password',
    });
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const { user } = this;

      //Submit
      try {
        await UserApi.forgotPassword(user);
        this.emailedUser = true;
      }
      catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
