<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <img src="/images/morble-logo.png" class="AppLogo" :class="{'AppLogo--s': isAuthenticated}">
    </div>

    <!-- Authenticated -->
    <div v-if="isAuthenticated" class="PageBlock">
      <div v-if="message" class="message">
        <p class="text-center">{{message}}</p>
      </div>

      <router-link
        :to="{name: 'morble.overview'}"
        class="Button Button--main Button--purpleGradient"
      >
        <div class="ButtonContent">
          <i class="Icon">fiber_smart_record</i>
          My Morbles
        </div>
      </router-link>
      <a
        :href="orderUrl"
        class="Button Button--main Button--pinkGradient"
        target="_blank"
      >
        <div class="ButtonContent">
          <i class="Icon">add</i>
          Create a Morble
        </div>
      </a>
      <router-link
        :to="{name: 'morble.scan'}"
        class="Button Button--main Button--orangeGradient"
      >
        <div class="ButtonContent">
          <i class="Icon">search</i>
          Scan a Morble
        </div>
      </router-link>
      <div class="ButtonContainer">
        <button class="Button Button--s Button--muted" @click="logout">
          Logout
        </button>
      </div>
    </div>

    <!-- Not authenticated -->
    <div v-else class="PageBlock">
      <div class="ButtonContainer">
        <router-link to="/login" class="Button Button--s Button--primary">
          Login
        </router-link>
        <button v-if="canShare" to="/share" class="Button Button--s" @click="share">
          Share
        </button>
        <button class="Button Button--s" @click="$router.push('register')">
          <i class="Icon">person_add</i>
          Create account
        </button>
      </div>
      <info-footer>
        <a href="https://morble.co">What is Morble?</a>
      </info-footer>
    </div>
  </div>
</template>

<script>
import InfoFooter from '@/components/shared/info-footer';

export default {
  components: { InfoFooter },

  computed: {
    message() {
      return this.$route.query?.message;
    },
  },

  data() {
    return {
      isAuthenticated: false,
      canShare: false,
      orderUrl: this.$cfg.app.orderUrl,
    };
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: '',
    });

    //Check if authenticated
    this.isAuthenticated = this.$auth.hasToken();
    this.canShare = !!navigator.share;
  },

  methods: {
    logout() {
      this.$auth.logout();
      this.isAuthenticated = false;
    },

    share() {
      if (navigator.share === undefined) {
        return;
      }
      navigator.share({
        title: 'Morble',
        text: 'Check out Morble!',
        url: 'https://morble.co',
      })
      .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-bottom: 1.5rem;
}
</style>
