<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      facebookPhotos: [],
    };
  },

  computed: {
    ...mapState({
      facebookAccessToken: state => state.session.facebookAccessToken,
    }),
  },

  created() {
    window.FB.api(
      '/me/photos/uploaded',
      'GET',
      {
        access_token: this.facebookAccessToken,
        fields: 'images',
        limit: 50,
      },
      response => {
        // each image comes back with a set of different sizes
        this.facebookPhotos = response.data.map(
          ({ images }) => ({
            thumb: images.find(
              ({ width }) => width > 300 && width < 500,
            )?.source,
            full: images[0]?.source,
          }),
        );
      },
    );
  },
};
</script>

<template>
  <div class="facebookPhotos">
    <img
      v-for="photo in facebookPhotos"
      :key="photo.thumb"
      :src="photo.thumb"
      @click="$emit('input', photo.full)"
    />
  </div>
</template>

<style lang="scss" scoped>
.facebookPhotos {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  gap: 10px;
  img {
    max-width: 30%;
    height: 100%;
  }
}
</style>
