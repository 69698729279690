import BaseModel from './base.model';
import MorbleApi from '@/api/morble.api';
import auth from '@/services/auth';
import cfg from '@/services/cfg';

/**
 * Morble model
 */
export default class Morble extends BaseModel {

  /**
   * Constructor
   */
  constructor(data, parent) {
    super(data, parent);

    //Image URL property
    Object.defineProperty(this, 'imageUrl', {
      enumerable: false,
      get() {
        if (!this.image) {
          return '';
        }
        const {id} = this;
        const {path} = this.image;
        const qs = auth.getQueryString();
        const base = cfg.api.baseUrl;
        return `${base}/morble/${id}/image/${path}${qs}`;
      },
    });
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return MorbleApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    //Update
    return MorbleApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const {id} = this;
    return MorbleApi.remove(id);
  }
}
