<template>
  <div class="Page Page--centered">
    <div class="PageBlock PageBlock--padded" v-if="isLoading">
      <spinner />
    </div>
    <div class="PageBlock" v-if="!isLoading && isInvalid">
      <p class="text-center">Morble or gallery not found</p>
    </div>

    <div class="PageBlock" v-if="!isLoading">
      <div v-if="!isInvalid">
        <h2 class="text-center">{{morble.name}}</h2>

        <JSCoverflow
          :artefacts="artefacts"
          :viewArtefact="viewArtefact"
        />
      </div>

      <div class="ButtonContainer">
        <router-link
          :to="{name: 'morble.overview'}"
          class="Button Button--s Button--muted">
          Back
        </router-link>
        <div
          v-if="hasFacebookProfile"
          @click="showFacebookPhotos = true"
          class="Button Button--s"
          type="button"
        >
          Add items from Facebook
        </div>

        <label class="FileDrop-label" v-if="!isUploading">
          <div class="Button Button--s FileDrop-button" type="button">
            Add item
          </div>

          <input
            class="FileDrop-input"
            :accept="accept"
            :pattern="pattern"
            :multiple="true"
            type="file"
            @change="handleSelectedFiles"
          />
        </label>
        <spinner v-else />
      </div>
    </div>

    <div class="PageBlock PageBlock--overflow" v-if="showArtefact">
      <div class="PhotoZoom">
        <img :src="artefact.url" v-if="artefact.isPhoto">
        <video controls v-if="artefact.isVideo" autoplay ref="video">
          <source :src="artefact.url" type="video/mp4">
        </video>
      </div>
      <div class="PageBlock" v-if="!isLoading">
        <div class="ButtonContainer">
          <div class="Button Button--s Button--muted" @click="showGallery">
            Back
          </div>
          <div v-if="artefact.type == 'video'" class="Button Button--s" @click="thumbnailPicker">
            Change thumbnail
          </div>
          <div class="Button Button--s" @click="remove(artefact)">
            Remove
          </div>
        </div>
      </div>
    </div>

    <div v-if="showThumbnailPicker" class="PageBlock PageBlock--overflow thumbnailPicker">
      <div class="thumbnailOptions">
        <div
          v-for="(thumbnail, index) in artefact.thumbOptionUrls"
          v-bind:key="index"
          class="thumbnailOption"
        >
          <img
            :src="thumbnail + '?' + cacheBreaker"
            @click="setThumbnail(artefact, index)"
          />
          <spinner v-if="thumbnailLoadingIndex === index" class="thumbnailOverlaySpinner" />
        </div>
      </div>
      <div class="ButtonContainer">
        <div
          v-if="!isRegeneratingThumbnails"
          class="Button Button--s Button--muted"
          @click="regenerateThumbnails"
        >
          Regenerate thumbnails
        </div>
        <spinner v-else />
        <div class="Button Button--s Button--muted" @click="showGallery">
          Back
        </div>
      </div>
    </div>

    <div v-if="showFacebookPhotos" class="PageBlock PageBlock--overflow">
      <FacebookPhotos @input="chooseFacebookPhoto" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MorbleApi from '@/api/morble.api';
import ArtefactApi from '@/api/artefact.api';
import { NotFoundError, NotAuthorizedError } from '@/errors';
import { PHOTO, VIDEO} from '@/constants/artefact-types';
import JSCoverflow from '@/vendors/js-coverflow/js-coverflow';
import FacebookPhotos from './facebook-photos.vue';

export default {
  components: { JSCoverflow, FacebookPhotos },

  props: {
    id: String,
    code: String,
  },

  data() {
    return {
      morble: {},
      artefact: {},
      gallery: null,
      artefacts: [],
      isLoading: false,
      isUploading: false,
      isRegeneratingThumbnails: false,
      isInvalid: false,
      showGrid: false,
      showArtefact: false,
      showThumbnailPicker: false,
      thumbnailLoadingIndex: -1,
      auto: 100,
      numSlides: 5,
      accept: 'image/jpeg,image/jpg,video/mp4,image/png,image/gif',
      pattern: '.jpeg,.jpg,.mp4,.png,.gif',
      showFacebookPhotos: false,
      cacheBreaker: null,
    };
  },

  computed: {
    ...mapState({
      user: state => state.session.user,
    }),

    hasFacebookProfile() {
      return this.user?.profiles.some(({provider}) => provider === 'facebook');
    },
  },

  async mounted() {
    this.resizeCarousel();

    this.isLoading = true;

    //Load morble and artefacts
    try {
      let morble = {};
      if (this.code) {
        morble = await MorbleApi.findByCode(this.code);
      }
      else {
        morble = await MorbleApi.findById(this.id);
      }

      //No morble or gallery?
      if (!morble || !morble.gallery) {
        throw new NotFoundError();
      }

      const artefacts = await this.loadArtefacts(morble.gallery.id);

      this.morble = morble;
      this.gallery = morble.gallery;
      this.artefacts = artefacts;
    }
    catch (error) {
      if (error instanceof NotAuthorizedError) {
        // logged in
        if (this.$auth.hasToken()) {
          return this.$router.push({
            name: 'home',
            query: { message: `This is a private Morble. If you know the owner you could ask them to share it with you.` },
          });
        }
        else {
          return this.$router.push({
            name: 'login',
            query: {
              redirect: `${this.code}`,
              message: `This morble is private. Please log in to view it.`,
            },
          });
        }
      }
      this.isInvalid = true;
      this.isLoading = false;
      this.$err.process(error);
    }

    this.isLoading = false;

    //Add resize window listener
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.resizeCarousel();
      });
    });
  },

  beforeRouteLeave(to, from, next) {
    window.coverflow_current_index = 0;
    next();
  },

  methods: {
    setMorble(morble) {
      this.morble = morble;
    },

    resizeCarousel() {
      const width = window.innerWidth;

      switch (true) {
        case (width < 600):
          this.auto = width / 1.5;
          this.numSlides = 3;
          break;
        case (width > 1000):
          this.auto = width / 3;
          this.numSlides = 7;
          break;
        default:
          this.auto = width / 2;
          this.numSlides = 5;
      }
    },

    async loadArtefacts(gallery) {
      return await ArtefactApi
        .query({ gallery })
        .then(data => data.artefacts);
    },

    viewArtefact(i) {
      this.artefact = this.artefacts[i];
      this.showArtefact = true;
    },

    async remove(artefact) {
      const { type } = artefact;
      await artefact.remove();

      //Remove from overview
      const i = this.artefacts.indexOf(artefact);
      if (i !== -1) {
        this.artefacts.splice(i, 1);
      }
      this.showArtefact = false;
      this.$notify(`Your ${type} has been removed`);
    },

    showGallery() {
      this.showThumbnailPicker = false;
      this.showArtefact = false;
    },

    thumbnailPicker() {
      this.$refs.video.pause();
      if (!this.artefact.video?.thumbOptionsPaths?.length > 0) {
        this.regenerateThumbnails();
      }
      this.showThumbnailPicker = true;
    },

    handleSelectedFiles(event) {
      const { artefact: maxSizes } = this.$cfg.maxFileSize;
      const types = {
        'image/jpeg': 'photo',
        'image/jpg': 'photo',
        'image/png': 'photo',
        'image/gif': 'photo',
        'video/mp4': 'video',
      };
      const files = Array.from(event.target.files);
      const isTooLarge = files.some(file => file.size > maxSizes[types[file.type]]);
      if (isTooLarge) {
        this.$notify('Sorry, this file is too large to upload');
        return;
      }
      this.selectFiles(event.target.files);
    },

    async selectFiles(files) {

      //Not an array?
      if (!Array.isArray(files)) {
        files = Array.from(files);
      }

      //Anything to do?
      if (files.length === 0) {
        return;
      }

      //Validate type
      const accept = this.accept
        .replace(/,/g, '|')
        .replace(/\+/g, '\\+');
      if (accept && !files.every(file => file.type.match(accept))) {
        return this.isErrorFileType = true;
      }

      this.isErrorFileType = false;

      //upload files
      await this.setFiles(files);
    },

    async setFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      //Already uploading
      if (this.isUploading) {
        return;
      }

      //Flag as uploading
      this.isPickingFiles = false;
      this.isUploading = true;
      this.numToUpload = files.length;
      this.numUploading = 1;

      //Get gallery ID
      const {id} = this.gallery;

      //Upload files one by one
      for (const file of files) {

        //Create form data instance
        const formData = new FormData();
        const {name} = file;
        const type = file.type.match(/image/) ? PHOTO : VIDEO;

        //Set form data
        formData.set('gallery', id);
        formData.set('type', type);
        formData.set('name', name);
        formData.append(type, file, name);

        //Create artefact
        try {
          const artefact = await ArtefactApi.create(type, formData);
          this.artefacts.push(artefact);
          setTimeout(() => {
            window.coverflow_player.to(this.artefacts.length - 1);
          }, 1000);
        }
        catch (error) {
          this.$err.process(error);
        }

        //Update
        this.numUploading++;
      }

      this.isUploading = false;
      this.artefacts = await this.loadArtefacts(id);
    },

    async setThumbnail(artefact, thumbIndex) {
      this.thumbnailLoadingIndex = thumbIndex;
      const updatedArtefact = await artefact.setThumbnail(thumbIndex);
      this.artefact = updatedArtefact;

      const a = this.artefacts.find(a => a.id === updatedArtefact.id);
      a[a.type].pathThumb = updatedArtefact[updatedArtefact.type].pathThumb;

      this.showGallery();
      this.thumbnailLoadingIndex = -1;
    },

    async chooseFacebookPhoto(url) {
      this.showFacebookPhotos = false;

      const {
        0: filename,
        1: type,
      } = url.match(/[^/\\&?]+\.(png|jpg|mp4)(?=([?&].*$|$))/);

      const data = await fetch(url).then(res => res.blob());
      const file = new File([data], filename, { type: `image/${type}` });

      await this.setFiles([file]);
    },

    async regenerateThumbnails() {
      this.isRegeneratingThumbnails = true;
      this.artefact = await this.artefact.regenerateThumbnails();
      this.cacheBreaker = new Date().getTime();
      this.isRegeneratingThumbnails = false;
    },
  },
};
</script>

<style scoped lang="scss">
.PhotoZoom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.FileDrop-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FileDrop-input {
  display: none;
}

video {
  width: 100%;
  height: 100%;
}

.thumbnailOptions {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
}
.thumbnailOption {
  position: relative;
  max-width: 47%;
  margin: 6px 4px;
  cursor: pointer;
  @include breakPointMiddle {
    max-width: 32%;
  }

  .thumbnailOverlaySpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>
