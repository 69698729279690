<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <router-link :to="{name: 'home'}">
        <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
      </router-link>
    </div>
    <div class="PageBlock PageBlock--textCenter">

      <!-- Generic error -->
      <div v-if="isGenericError">
        <p>Something went wrong on our end. Please try again or come back later.</p>
      </div>

      <!-- Network error -->
      <div v-if="isNetworkError">
        <p>There seem to be some network issues. Please check your internet connection and try again.</p>
      </div>

      <!-- Not found error -->
      <div v-if="isNotFoundError">
        <p>Morble or gallery not found.</p>
      </div>

      <!-- Not authorized error -->
      <div v-if="isNotAuthorizedError">
        <p>You don’t have access to this morble or gallery</p>
      </div>
    </div>
    <div class="PageBlock">
      <div class="ButtonContainer">
        <button class="Button Button--s Button--muted" @click="back">
          Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  NetworkError,
  NotFoundError,
  NotAuthorizedError,
} from '@/errors/index';

export default {
  props: {
    error: Error,
  },
  computed: {
    isNetworkError() {
      return (this.error instanceof NetworkError);
    },
    isNotFoundError() {
      return (this.error instanceof NotFoundError);
    },
    isNotAuthorizedError() {
      return (this.error instanceof NotAuthorizedError);
    },
    isGenericError() {
      return (
        !this.isNetworkError &&
        !this.isNotFoundError &&
        !this.isNotAuthorizedError
      );
    },
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Error',
    });
  },

  /**
   * Methods
   */
  methods: {

    /**
     * Go back
     */
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
