import merge from 'deepmerge';
import base from './base';

/**
 * Production environment configuration
 */
export default merge.all([base, {
  api: {
    baseUrl: 'https://morble.herokuapp.com',
  },

  app: {
    baseUrl: 'https://morble.co',
  },

  facebook: {
    appId: '109354305503379',
  },

  //Google analytics
  googleAnalytics: {
    trackingId: 'UA-134202233-2',
  },

  //Sentry
  sentry: {
    dsn: `https://8ce8e91617004ee4bfd5c294497cc30e@sentry.io/1328204`,
  },
}]);
