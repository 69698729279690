import { SET_USER, SET_FACEBOOK_ACCESS_TOKEN } from './types';

/**
 * Mutations for the Session state
 */
export default {

  /**
   * Set logged in user
   */
  [SET_USER](state, user) {
    state.user = user;
  },

  [SET_FACEBOOK_ACCESS_TOKEN](state, facebookAccessToken) {
    state.facebookAccessToken = facebookAccessToken;
  },
};
