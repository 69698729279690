
/**
 * Import the various environments we support
 */
import base from './base';
import dev from './dev';
import staging from './staging';
import production from './production';

/**
 * Export as map so the service can access it dynamically
 */
export default {
  base, dev, staging, production,
};
