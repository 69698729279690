import merge from 'deepmerge';
import base from './base';

const basePath = process.env.LOCAL_IP || 'localhost';

/**
 * Development environment configuration
 */
export default merge.all([base, {
  api: {
    baseUrl: `http://${basePath}:8081/api`,
  },

  app: {
    baseUrl: `http://${basePath}:8080`,
  },

  facebook: {
    appId: '723195025649334',
  },

  errors: {
    log: true,
  },
}]);
