
/**
 * Setup page data
 */
export const setup = async ({commit}, data) => {
  const {title} = data;

  commit('setPageTitle', title);
};

/**
 * Clear page data
 */
export const clear = async ({commit}) => {

  //Clear data
  commit('clearPageTitle');
};
