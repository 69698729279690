
//Vue app component
import Vue from 'vue';
import App from './components/app/app.vue';

//3rd party
import VueAnalytics from 'vue-analytics';
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
import Notifications from 'vue-notification';
Vue.use(Notifications);

//Services
import router from './router';
import store from './store';
import cfg from './services/cfg';
import auth from './services/auth';
import errors from './services/errors';
import facebookSDK from './vendors/facebook-sdk';

//Service worker
import './register-service-worker';

//Global styles
import './styles/reset.scss';
import './styles/utility.scss';
import './styles/elements.scss';
import './styles/shared.scss';

//Shared components
import Spinner from './components/shared/spinner';
Vue.component('spinner', Spinner);

//Filters
import momentFilter from './filters/moment';
Vue.filter('moment', momentFilter);

//Google analytics
if (cfg.googleAnalytics.trackingId) {
  Vue.use(VueAnalytics, {
    id: cfg.googleAnalytics.trackingId,
    router,
    autoTracking: {
      screenview: true,
    },
  });
}

//Global Vue configuration
// Vue.prototype.$log = console.log.bind(console);
Vue.config.productionTip = false;
Vue.config.errorHandler = function(error) {
  errors.process(error, false);
};

facebookSDK().then(() => {
  //Instantiate app
  new Vue({
    router,
    store,
    cfg,
    auth,
    errors,
    render: h => h(App),
  }).$mount('#app');
});
