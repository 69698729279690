<template>
  <p class="InfoFooter">
    <slot />
  </p>
</template>

<script>
export default {};
</script>

<style lang="scss">
.InfoFooter {
  padding-top: $spacing;
	text-align: center;
	font-size: $fontSizeSmall;
	a {
		color: $colorTextMuted;
	}
}
</style>
