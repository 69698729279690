import ResponseError from './response';

/**
 * Server error
 */
export default class ServerError extends ResponseError {

  /**
   * Constructor
   */
  constructor(message, data, status = 500) {

    //Parent constructor
    super(message, data, status);
  }
}
