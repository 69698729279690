
/**
 * Base 64 decode URL string
 */
export function fromBase64(str) {
  let o = str.replace('-', '+').replace('_', '/');
  switch (o.length % 4) {
    case 0:
      break;
    case 2:
      o += '==';
      break;
    case 3:
      o += '=';
      break;
    default:
      throw 'Illegal base64 url string';
  }
  try {
    return decodeURIComponent(window.atob(o));
  }
  catch (e) {
    //Fall through
  }
}
