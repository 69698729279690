import ResponseError from './response';

/**
 * Network error
 */
export default class NetworkError extends ResponseError {

  /**
   * Constructor
   */
  constructor(message, data, status = -1) {

    //Parent constructor
    super(message, data, status);
  }
}
