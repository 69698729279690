import ServerError from './server';

/**
 * Service unavailable error
 */
export default class ServiceUnavailableError extends ServerError {

  /**
   * Constructor
   */
  constructor(message, data) {

    //Parent constructor
    super(message, data, 503);
  }
}
