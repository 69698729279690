<template>
  <div class="Page Page--centered">

    <!-- Scanner -->
    <div class="PageBlock PageBlock--full PageBlock--centered" v-if="isScanning">
      <scanner @code="parseCode($event.code)"/>
    </div>

    <!-- Loading -->
    <div class="PageBlock PageBlock--padded" v-if="isLoading">
      <spinner />
    </div>

    <!-- Invalid code -->
    <div class="PageBlock" v-if="hasError">
      <p class="text-center" v-if="isTimedOut">Could not detect QR code</p>
      <p class="text-center" v-if="isInvalidCode">Invalid QR code</p>
      <div class="ButtonContainer">
        <router-link
          class="Button Button--s Button--muted"
          :to="{name: 'home'}"
        >Cancel</router-link>
        <button
          class="Button Button--s Button--primary"
          @click="startScanning"
        >Retry</button>
      </div>
    </div>
  </div>
</template>

<script>
import Scanner from '@/components/shared/scanner';

export default {
  components: { Scanner },

  data() {
    return {
      hasError: false,
      isScanning: false,
      isLoading: false,
      isInvalidCode: false,
      isTimedOut: false,
      scanTimeout: 30, //Seconds
    };
  },

  mounted() {
    this.startScanning();
  },

  destroyed() {
    this.stopScanning();
  },

  methods: {

    /**
     * Parse inbound code
     */
    parseCode(qr) {

      const {data} = qr;
      const base = this.$cfg.app.baseUrl.replace(/^(http|https):\/\//, '');
      const regex = new RegExp(`${base}/[A-Za-z0-9]{5,6}`);

      this.stopScanning();

      //Check if it matches the base URL for morble
      if (!data.match(regex)) {
        return this.setInvalidCodeError();
      }

      //Extract code from link data
      const parts = data.split('/');
      const code = parts[parts.length - 1];

      this.$router.push({ name: 'link', params: { code } });
    },

    stopScanning() {
      this.isScanning = false;
      clearTimeout(this.timeout);
    },

    startScanning() {

      //Reset flags
      this.hasError = false;
      this.isTimedOut = false;
      this.isInvalidCode = false;
      this.isLoading = false;

      //Mark as scanning
      this.isScanning = true;

      //Timeout after a while
      this.timeout = setTimeout(() => {
        this.isScanning = false;
        this.hasError = true;
        this.isTimedOut = true;
      }, this.scanTimeout * 1000);
    },

    setInvalidCodeError() {
      this.hasError = true;
      this.isInvalidCode = true;
    },
  },
};
</script>
