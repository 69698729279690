import http from '@/services/http';

export default {

  /**
	 * Get data for logged in user
	 */
  me() {
    return http
      .get('/user/me');
  },

  register(user, oAuth = false) {
    if (oAuth) {
      return http.post('/user/register/oauth', { user });
    }
    else {
      return http.post('/user/register', { user });
    }
  },

  /** Finish creating user, after email verification and collecting demographic details */
  confirm(user, token) {
    return http
      .post('/user/confirm', { user, token });
  },

  updateOwn(data) {
    return http
      .put(`/user/me`, data);
  },

  forgotPassword(user) {
    return http
      .post('/user/forgotPassword', {user});
  },

  resetPassword(user, token) {
    return http
      .post('/user/resetPassword', { user, token });
  },
};
