
/**
 * Artefact types
 */
export const PHOTO = 'photo';
export const VIDEO = 'video';

/**
 * Default object
 */
export default {
  PHOTO,
  VIDEO,
};
