<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <router-link
        :to="{name: 'home'}"
      >
        <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
      </router-link>
    </div>

    <div class="PageBlock PageBlock--contained">
      <h1>Finish creating your account</h1>

      <p>Hi, {{user.name}}! Thanks for verifying your email address.</p>

      <p>Please tell us a little bit about yourself.</p>

      <p class="text-center" v-if="error">{{error}}</p>

      <div class="Group">
        <input
          v-model="user.city"
          type="text"
          class="Input"
          placeholder="Your city"
        >
        <div class="InputHint" v-if="$v.user.city.$error">
          <p v-if="!$v.user.city.required">City is required</p>
        </div>
      </div>

      <div class="ButtonContainer">
        <button
          class="Button Button--s"
          @click="confirm"
        >
          Skip
        </button>
        <button
          class="Button Button--s Button--primary"
          @click="finalise"
          :disabled="isSaving"
        >
          Onward! <i class="Icon">chevron_right</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import { required } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      error: '',
      isSaving: false,
      user: Object,
    };
  },

  props: {
    accessToken: String,
  },

  created() {
    this.$auth.setToken(this.accessToken);
    this.user = {
      name: this.$auth.token.payload.name,
      email: this.$auth.token.payload.email,
    };
  },

  beforeMount() {
    this.$store.dispatch('page/setup', {
      title: 'Finalise your account',
    });
  },

  validations: {
    user: {
      city: { required },
    },
  },

  methods: {
    finalise() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.confirm(true);
    },

    async confirm(updated = false) {
      this.isSaving = true;
      const { user } = this;
      let auth;

      try {
        auth = await UserApi.confirm(user, this.accessToken);
      }
      catch (err) {
        this.error = 'errors.' + err.message;
      }
      finally {
        if (updated) {
          this.$notify('Successfully updated your details');
        }
        this.$auth.loginWithToken(auth.access_token);
      }
    },
  },
};
</script>
