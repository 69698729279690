import ClientError from './client';

/**
 * Exists error
 */
export default class ExistsError extends ClientError {

  /**
   * Constructor
   */
  constructor(message, data) {

    //Parent constructor
    super(message, data, 409);
  }
}
