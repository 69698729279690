<!--
  MHJB: Wrapping a slightly customised version of https://wesleyluyten.com/projects/js-cover-flow/,
  which lives in /public/js/js-coverflow/

  The coverflow controller can be accessed with window.coverflow (for e.g. window.coverflow.to(5))
 -->

<template>
  <div id="player"></div>
</template>

<script>

export default {
  props: {
    artefacts: {
      type: Array,
      required: true,
    },
    viewArtefact: { type: Function },
  },

  watch: {
    artefacts: {
      deep: true,
      immediate: true,
      handler() {
        if(this.artefacts.length) {
          const playlist = this.artefacts.map(
            ({ thumbUrl: image, type }) => ({ image, type })
          );

          this.$nextTick(() => {
            // Cleared by morble/view:beforeRouteLeave
            const current_item = window.coverflow_current_index || 0;

            coverflow('player').remove();
            coverflow('player').setup({
              playlist,
              width: '100%',
              height: 500,
              coverwidth: 300,
              coverheight: 300,
              coverangle: 70,
              fixedsize: true,
              coveroffset: 200,
              reflectionopacity: 0.5,
              reflectionratio: 100,
              reflectionoffset: 1.5,

              // Key settings for swipe experience
              covergap: 60,
              tweentime: 2,
              deltaMultiplier: 150,

              item: current_item,
            });
            coverflow('player').on('click', this.viewArtefact);
          })
        }
      },
    },
  },

  mounted() {
    const loadScript = src => {
      const el = document.createElement('script');
      el.src = src;
      el.async = false;
      document.head.appendChild(el);
      return el;
    }

    const loadCSS = src => {
      const el = document.createElement('link');
      el.href = src;
      el.rel = 'stylesheet';
      document.head.appendChild(el);
      return el;
    }

    [
      'main', '_', 'api', 'controller', 'cover',
      'flash', 'hit', 'html5', 'modernizr',
      'playlistloader', 'signal', 'coverflow'
    ].map(name => loadScript(`/js/js-coverflow/${name}.js`))

    loadCSS('/js/js-coverflow/coverflow.css');
  },
};
</script>
