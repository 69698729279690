<template>
  <div>
    <app-header />
    <div class="Container AppContent">
      <div class="AppLoader" v-if="isLoading">
        <spinner />
      </div>
      <router-view v-else />
    </div>
    <div class="AddToHomeScreen" v-if="showAddToHomeScreen && homeScreenEvent">
      <p>
        <a @click="addToHomeScreen">Add Morble to home screen?</a>
      </p>
      <i class="Icon" @click="showAddToHomeScreen = false">close</i>
    </div>
    <app-footer />
    <notifications
      position="bottom center"
      width="100%"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import auth from '@/services/auth';

//Components
import AppHeader from './header/header';
import AppFooter from './footer/footer';

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      homeScreenEvent: null,
      showAddToHomeScreen: false,
      isLoading: true,
    };
  },

  async created() {

    auth.init();

    // MHJB: Disabling for now - doesn't make sense to authenticate every page, only them what need it
    // //Load session user
    // try {
    //   await this.loadUser();
    // }
    // catch (error) {
    //   this.$err.process(error);
    // }
    // finally {
    //   this.isLoading = false;
    // }
    this.isLoading = false;
  },

  mounted() {

    //Add to home screen prompt
    window.addEventListener('beforeinstallprompt', event => {
      this.promptAddToHomeScreen(event);
    });
  },

  methods: {

    /**
     * Map session store actions to use as component methods
     */
    ...mapActions('session', [
      'loadUser',
    ]),

    promptAddToHomeScreen(event) {
      this.homeScreenEvent = event;
      this.showAddToHomeScreen = true;
    },

    addToHomeScreen() {

      //Hide again
      this.showAddToHomeScreen = false;

      //No event?
      if (!this.homeScreenEvent) {
        return;
      }

      this.homeScreenEvent.prompt();
      this.homeScreenEvent.userChoice
        .then((choice) => {
          if (choice.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          }
          else {
            console.log('User dismissed the A2HS prompt');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.AppLogo {
  max-width: 12rem;
  margin: 0 auto;
  display: block;
}
.AppLogo--s {
  max-width: 6rem;
}
.AppLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
.AddToHomeScreen {
  background: $colorWhite;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: $spacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Icon {
    color: #ccc;
  }
}
</style>
