import http from '@/services/http';

/**
 * Authentication API service
 */
export default {

  /**
	 * Obtain token
	 */
  async token(data) {
    return http
      .post('/auth/token', data, {
        ignore401: true,
        withCredentials: true,
      })
      .then(data => {
        const {
          access_token: accessToken,
          token_type: tokenType,
          expires_in: expiresIn,
          scope,
        } = data;
        return {accessToken, tokenType, expiresIn, scope};
      });
  },

  /**
   * Revoke current refresh token
   */
  revoke() {
    return http
      .get('/auth/revoke', null, {
        ignore401: true,
        withCredentials: true,
      });
  },

  /**
   * Revoke all refresh tokens
   */
  revokeAll() {
    return http
      .get('/auth/revoke/all', null, {
        ignore401: true,
        withCredentials: true,
      });
  },
};
