
/**
 * Base configuration
 */
export default {

  //App
  app: {
    title: 'Morble',
    orderUrl: 'https://morble.co/',
  },

  //Google analytics
  googleAnalytics: {
    trackingId: '',
  },

  //Storage
  storage: {
    prefix: 'morble',
  },

  //Auth
  auth: {
    clientId: 'morble.client',
    homeRoute: 'home',
    loginRoute: 'login',
    allowedUnauthenticated: [
      'login', 'oAuthCallback', 'home',
    ],
  },

  //Sentry
  sentry: {
    dsn: '',
    tags: {
      source: 'client',
    },
    ignoreErrors: [
      `ResizeObserver loop limit exceeded`,
    ],
  },

  //Error handling
  errors: {
    log: false,
  },

  //File sizes (in bytes)
  maxFileSize: {
    morble: {
      image: 10 * 1048576,
    },
    artefact: {
      photo: 5 * 1048576,
      video: 25 * 1048576,
    },
  },
};
