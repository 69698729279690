import ClientError from './client';

/**
 * Not found error
 */
export default class NotFoundError extends ClientError {

  /**
   * Constructor
   */
  constructor(message, data) {

    //Parent constructor
    super(message, data, 404);
  }
}
