import merge from 'deepmerge';
import production from './production';

/**
 * Staging environment configuration
 */
export default merge.all([production, {
  api: {
    baseUrl: 'https://morble-staging.herokuapp.com',
  },

  app: {
    baseUrl: 'https://staging.morble.co',
  },

  facebook: {
    appId: '723195025649334',
  },

  googleAnalytics: {
    trackingId: 'UA-134202233-3',
  },

  errors: {
    log: true,
  },
}]);
