import Vue from 'vue';
import router from '@/router';
import cfg from '@/services/cfg';
import auth from '@/services/auth';
import sentry from '@/services/sentry';

/**
 * Import errors
 */
import {
  ResponseError,
  NetworkError,
  ClientError,
  ServerError,
  ExistsError,
  TimeoutError,
  ValidationError,
  NotFoundError,
  NotAuthorizedError,
  NotAuthenticatedError,
  ServiceUnavailableError,
} from '@/errors/index';

/**
 * Create mixin to load the errors service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.errors) {
      this.$err = options.errors;
    }
    else if (options.parent && options.parent.$err) {
      this.$err = options.parent.$err;
    }
  },
});

/**
 * Errors service class
 */
class ErrorsService {

  /**
   * Process error
   */
  process(error, redirect = true, replace = true) {

    //Log to console
    if (cfg.errors.log) {
      //eslint-disable-next-line no-console
      console.error(error);
    }

    //Capture in Sentry
    if (sentry.isEnabled) {
      sentry.captureException(error);
    }

    //Not authenticated errors, log user out (this will redirect to login)
    if (error instanceof NotAuthenticatedError) {
      return auth.logout(true);
    }

    //Not redirecting
    if (!redirect) {
      return;
    }

    //Error route
    const route = {name: 'error', params: {error}};
    const method = replace ? 'replace' : 'push';

    //Redirect to error page
    router[method](route);
  }

  /**
   * Get error class for response errors
   */
  getClass(response) {

    //Network error
    if (typeof response === 'undefined') {
      return NetworkError;
    }

    //Get data
    const {status} = response;

    //Unauthenticated errors
    if (status === 401) {
      return NotAuthenticatedError;
    }

    //Unauthorized errors
    else if (status === 403) {
      return NotAuthorizedError;
    }

    //Not found errors
    else if (status === 404) {
      return NotFoundError;
    }

    //Exists errors
    else if (status === 409) {
      return ExistsError;
    }

    //Validation errors
    else if (status === 422) {
      return ValidationError;
    }

    //Service unavailable errors
    else if (status === 503) {
      return ServiceUnavailableError;
    }

    //Request timeout
    else if (status === 408 || status === 504) {
      return TimeoutError;
    }

    //Generic client errors
    else if (status >= 400 && status <= 499) {
      return ClientError;
    }

    //Server errors
    else if (status >= 500 && status <= 599) {
      return ServerError;
    }

    //Generic response errors
    return ResponseError;
  }
}

/**
 * Export singleton instance
 */
export default new ErrorsService();
