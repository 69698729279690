<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
    </div>

    <div class="PageBlock PageBlock--padded" v-if="isLoggingIn">
      <spinner />
    </div>

    <div class="PageBlock" v-else-if="error">
      <div>
        <p>{{error}}</p>
      </div>
      <div class="ButtonContainer">
        <router-link :to="{name: 'login'}">
          <button
            class="Button Button--s Button--muted"
          >Back to login</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';

export default {
  data() {
    return {
      isLoggingIn: true,
      error: '',
    };
  },

  beforeRouteEnter(to, from, next) {
    const {
      access_token: accessToken,
      action,
      redirect,
      facebookAccessToken,
    } = to.query;

    //Got access token, login
    if (accessToken) {

      //Connecting existing user? Go to profile
      if (action === 'connect') {
        //TODO
      }

      auth.loginWithToken(accessToken, redirect, facebookAccessToken);
      return next();
    }

    next({ name: 'login' });
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Login',
    });
  },
};
</script>
