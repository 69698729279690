
/**
 * Mutations for the Session state
 */
export default {

  /**
   * Set page title
   */
  setPageTitle(state, title) {
    state.title = title;
  },

  /**
   * Clear page title
   */
  clearPageTitle(state) {
    state.title = '';
  },
};
