import http from '@/services/http';
import Morble from '@/models/morble.model';

export default {
  query(filter) {
    return http
      .get('/morble', filter)
      .then(data => Morble.createFrom(data, null, 'morbles'));
  },

  own() {
    return http
      .get('/morble/own')
      .then(data => Morble.createFrom(data, null, 'morbles'));
  },

  list(filter) {
    return http
      .get('/morble/list', filter)
      .then(morbles => Morble.createFrom(morbles));
  },

  findById(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .get(`/morble/${id}`)
      .then(data => Morble.createFrom(data));
  },

  findByCode(code) {
    if (!code) {
      throw new Error('Missing code');
    }

    return http
      .get(`/morble/findByCode/${code}`)
      .then(data => Morble.createFrom(data));
  },

  create(data) {
    return http
      .post('/morble', data);
  },

  update(id, data) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .put(`/morble/${id}`, data);
  },

  remove(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .delete(`/morble/${id}`);
  },
};
