<template>
  <div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      title: state => state.page.title,
    }),
  },
};
</script>

<style lang="scss">
</style>
