<template>
  <div class="Page Page--centered">
    <div class="PageBlock">
      <router-link :to="{name: 'home'}">
        <img src="/images/morble-logo.png" class="AppLogo AppLogo--s">
      </router-link>
    </div>

    <div class="PageBlock PageBlock--padded" v-if="isLoading">
      <spinner />
    </div>

    <div class="PageBlock" v-if="!isLoading && !hasMorbles">
      <p class="text-center">No morbles yet</p>
    </div>
    <div class="PageBlock Morbles" v-if="hasMorbles">
      <div
        class="Morble"
        v-for="morble in morbles"
        :key="morble.id"
      >
        <router-link :to="{name: 'morble.view', params: {id: morble.id}}" class="MorbleLink">
          <div class="MorbleSquare">
            <div class="MorbleImageWrapper">
              <img :src="morble.imageUrl" class="MorbleImage">
            </div>
          </div>
          <div class="MorbleName">{{morble.name}}</div>
        </router-link>
      </div>
    </div>
    <div class="PageBlock PageBlock--contained" v-if="!isLoading">
      <div class="ButtonContainer">
        <router-link :to="{name: 'home'}" class="Button Button--s Button--muted">
          Back
        </router-link>
        <a :href="orderUrl" class="Button Button--s Button--pinkGradient">
          Order more
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MorbleApi from '@/api/morble.api';

export default {
  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,

      morbles: [],

      isLoading: false,

      orderUrl: this.$cfg.app.orderUrl,
    };
  },

  computed: {
    hasMorbles() {
      return this.morbles.length > 0;
    },
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'My Morbles',
    });
  },

  created() {
    this.loadMyMorbles();
  },

  methods: {
    loadPage(page) {
      this.page = page;
      this.loadMyMorbles();
    },

    async loadMyMorbles() {
      this.isLoading = true;

      const data = await MorbleApi.own();

      this.processData(data);
      this.isLoading = false;
    },

    processData(data) {
      const { meta, morbles } = data;

      this.morbles = morbles;
      this.totalItems = meta.total;
    },
  },
};
</script>

<style lang="scss">
.Morbles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 60rem;
}
.Morble {
  flex: 0 0 50%;
  max-width: 20rem;
  @include breakPointSmall {
    padding: 2rem;
  }
}
.MorbleLink {
  padding-left: $spacing;
  padding-right: $spacing;
  display: flex;
  flex-direction: column;
  &:nth-child(n+3) {
    padding-top: $spacing;
  }
}
.MorbleSquare {
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
.MorbleImageWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.MorbleImage {
  width: 100%;
  height: 100%;
}
.MorbleName {
  color: $colorWhite;
  margin-top: 1.25rem;
  text-align: center;
}
</style>
