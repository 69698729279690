<template>
  <div class="AppFooter Container">

  </div>
</template>

<script>
export default {
};
</script>

<style scoped lang="scss">
.AppFooter {

}
</style>
